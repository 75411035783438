import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { faClipboard as faClipboardRegular } from '@fortawesome/free-regular-svg-icons';
import { QRCodeSVG } from 'qrcode.react';
import defaultLogo from '../../assets/Logo Prodexpo pequeño.png';
import pxpoLogo from '../../assets/Logo Prodexpo Icon.png';
import SwipeableModal from '../GenericModal/SwipeableModal';
import './StorePanel.css';
import { toPng } from 'html-to-image';

const StorePanel = ({ isOpen, onClose, storeName, storeUrl, storeLogo }) => {
  const [showCopied, setShowCopied] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [shareMode, setShareMode] = useState(null);
  const panelRef = useRef(null);
  const textareaRef = useRef(null);
  const [storeDescription, setStoreDescription] = useState('');

  const handleShare = async () => {
    try {
      setIsClicked(true);
      setTimeout(() => setIsClicked(false), 300);
      await navigator.clipboard.writeText(storeUrl);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Error al copiar:', err);
    }
  };

  const handleShareImage = async (orientation) => {
    setShareMode(orientation);
    try {
      const shareButton = panelRef.current.querySelector('.share-button');
      const descriptionSection = panelRef.current.querySelector('.description-section');
      const urlIcon = panelRef.current.querySelector('.store-url .fa-lg');
      const descriptionInput = panelRef.current.querySelector('.store-description-input');
      const isDescriptionEmpty = !textareaRef.current.value.trim();

      // Aplicar estilos según orientación
      if (orientation === 'horizontal') {
        panelRef.current.classList.add('horizontal');
      }

      // Ocultar descripción si está vacía en cualquier orientación
      if (isDescriptionEmpty) {
        descriptionSection.style.display = 'none';
      }

      // Ocultar elementos y modificar estilos antes de capturar
      shareButton.style.display = 'none';
      urlIcon.style.display = 'none';
      descriptionInput.style.border = 'none';

      const dataUrl = await toPng(panelRef.current, {
        quality: 0.95,
        backgroundColor: 'white'
      });

      // Restaurar los elementos y estilos
      shareButton.style.display = 'block';
      urlIcon.style.display = 'block';
      descriptionSection.style.display = 'block';
      descriptionInput.style.border = ''; // Restaurar el borde original
      panelRef.current.classList.remove('horizontal');

      const link = document.createElement('a');
      link.download = `${storeName || 'store'}-qr.png`;
      link.href = dataUrl;
      link.click();
    } catch (err) {
      console.error('Error al generar la imagen:', err);
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target.className === 'modal-backdrop') {
      onClose();
    }
  };

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    const lines = text.split('\n');
    const input = e.target;
    
    // Verificar límites
    const isOverCharLimit = text.length > 220;
    const isOverLineLimit = lines.length > 7;
    
    if (isOverCharLimit || isOverLineLimit) {
      input.style.border = '1px solid red';
      // Si excede algún límite, mantener el texto anterior
      e.target.value = storeDescription;
      return;
    }
    
    // Si está dentro de los límites, restaurar el borde y actualizar
    input.style.border = '1px solid #dbd9d9';
    setStoreDescription(text);
  };

  return (
    <SwipeableModal isOpen={isOpen} onClose={onClose}>
      <div className="store-panel" ref={panelRef}>        
        <div className={`qr-section ${isClicked ? 'clicked' : ''}`} onClick={handleShare}>
          <QRCodeSVG 
            value={storeUrl}
            size={230}
            level="H"
            title='QR de la tienda'
            imageSettings={{
              src: pxpoLogo,
              height: 50,
              width: 50,
              excavate: false,
            }}
            fgColor="#000000"
          />
          <div className="store-url">
            <span>{storeUrl}</span>
            <FontAwesomeIcon icon={faClipboardRegular} className="fa-lg" style={{color: "#444343", backgroundColor: "transparent"}} />
            {showCopied && <div className="copied-toast">¡Copiado!</div>}
          </div>
        </div>
        <div className="store-logo-container">
          <div className="store-logo">
            <img 
              src={storeLogo || defaultLogo} 
              alt={`${storeName || 'ProdExpo'} logo`}
              className="store-logo-img"
              />
          </div>
          <div className="store-name">{storeName}</div>
          <div className="description-section">
            <textarea 
              ref={textareaRef}
              type="text" 
              placeholder="Descripción de la tienda..." 
              className="store-description-input"
              onInput={handleTextareaInput}
              onChange={handleDescriptionChange}
              value={storeDescription}
              rows={1}
              maxLength={220}
            />
          </div>
        </div>
        
        

        <button className="share-button" onClick={() => setShareMode('options')}>
            <FontAwesomeIcon icon={faShare} /> Compartir
        </button>

        {shareMode === 'options' && (
          <div className="share-options">
            <button 
              className="share-orientation-button"
              onClick={() => handleShareImage('vertical')}
            >
              <FontAwesomeIcon icon={faSquare} className="vertical-icon" /> 
              <span>Vertical</span>
            </button>
            <button 
              className="share-orientation-button"
              onClick={() => handleShareImage('horizontal')}
            >
              <FontAwesomeIcon icon={faSquare} className="horizontal-icon" /> 
              <span>Horizontal</span>
            </button>
          </div>
        )}
      </div>
    </SwipeableModal>
  );
};

export default StorePanel;
