import authService from './AuthServices'; // Para manejar la autenticación

const API_URL = process.env.REACT_APP_API_URL;

const getStores = async () => {
  const token = localStorage.getItem('accessToken');
  const refreshToken = document.cookie.includes('refreshToken');

  // Si no hay tokens, devolver array vacío
  if (!token && !refreshToken) {
    return [];
  }

  try {
    let response = await fetch(`${API_URL}/stores/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      return await authService.handle401Error(getStores);
    }

    if (!response.ok) {
      throw new Error('Error al obtener las tiendas');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error en getStores:', error);
    throw error;
  }
};

export const createStore = async (name, route, logo) => {
  let token = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.append('name', name);
  formData.append('route', route);
  if (logo) {
    formData.append('file', logo);
  }

  try {
    let response = await fetch(`${API_URL}/stores`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });

    if (response.status === 401) {
      return await authService.handle401Error(() => createStore(name, route, logo));
    }

    if (!response.ok) {
      throw new Error('Error al crear la tienda');
    }

    return await response.json();
  } catch (error) {
    console.error('Error al crear la tienda:', error);
    throw error;
  }
};

const getStoreByRoute = async (route) => {
  try {
    let response = await fetch(`${API_URL}/stores/${route}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });


    if (!response.ok) {
      throw new Error('Error al obtener la tienda');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error en getStoreByRoute:', error);
    throw error;
  }
};

const storeServices = {
  getStores,
  createStore,
  getStoreByRoute,
  getFavoriteStores: async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/stores/favorites`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 401) {
        return await authService.handle401Error(storeServices.getFavoriteStores);
      }

      return await response.json();
    } catch (error) {
      console.error('Error al obtener favoritos:', error);
      throw error;
    }
  },

  toggleFavoriteStore: async (storeId) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/stores/favorites/${storeId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 401) {
        return await authService.handle401Error(() => storeServices.toggleFavoriteStore(storeId));
      }

      return await response.json();
    } catch (error) {
      console.error('Error al actualizar favorito:', error);
      throw error;
    }
  },

  checkFavoriteStatus: async (storeId) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/stores/favorites/check/${storeId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 401) {
        return await authService.handle401Error(() => storeServices.checkFavoriteStatus(storeId));
      }

      return await response.json();
    } catch (error) {
      console.error('Error al verificar favorito:', error);
      return { isFavorite: false };
    }
  },

  checkStoreRouteAvailability: async (route) => {
    try {
      const response = await fetch(`${API_URL}/stores/${route}`);
      return response.status === 404; // retorna true si la ruta está disponible (404 = no encontrada)
    } catch (error) {
      console.error('Error al verificar disponibilidad:', error);
      return false;
    }
  }
};

export default storeServices;
