import React, { useState, useEffect, useRef } from 'react';
import './StoreDrawer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import storeServices from '../../services/StoreServices';
import StorePanel from './StorePanel';

const StoreDrawer = ({ 
  isOpen, 
  storeName, 
  stores = [], 
  storeLogo, 
  onStorePanelOpen, 
  storeId,
  isFavorite,
  isLoadingFavorite,
  onFavoriteToggle,
  onClose
}) => {
  const navigate = useNavigate();
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && 
          !drawerRef.current.contains(event.target) && 
          !event.target.closest('.drawer-toggle')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();
    if (!storeId || isLoadingFavorite) return;
    onFavoriteToggle();
  };

  const handleStoreClick = (store) => {
    navigate(`/${store.route}`);
  };

  return (
    <div 
      ref={drawerRef}
      className={`store-drawer ${isOpen ? 'open' : ''}`}
    >
      <button 
        onClick={handleFavoriteClick} 
        disabled={isLoadingFavorite}
        className="favorite-button"
      >
        <FontAwesomeIcon 
          icon={isFavorite ? faStarSolid : faStarRegular} 
          style={{color: "#74C0FC"}} 
        />
      </button>
      <span className="store-drawer-name">{storeName}</span>
      <button className='store-panel-button' onClick={onStorePanelOpen}>
        <FontAwesomeIcon icon={faAddressCard} style={{color: "#74C0FC"}} />
      </button>

      {stores?.length > 0 && (
        <div className="store-selector">
          {stores.map(store => (
            <div 
              key={store.id} 
              className="store-option"
              onClick={() => handleStoreClick(store)}
            >
              {store.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StoreDrawer; 