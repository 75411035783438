import React from 'react';

function NotFoundPage() {
  return (
    <div>
        <header>
        </header>
        <h1 className='home-title'>Parece que esta tienda aun no esta ocupada,te gustaria tenerla?</h1>
      {/* Aquí podemos agregar más contenido después */}
    </div>
  );
}

export default NotFoundPage;