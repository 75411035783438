import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import StoreServices from '../services/StoreServices';
import './HomePage.css';
import { Link } from 'react-router-dom';
import shoppingw from '../assets/shopping_2.jpg';
import stepsImg from '../assets/steps.png';
import { useLoginModal } from '../components/Login/LoginModalContext';
import CreateStoreModal from '../components/Store/CreateStoreModal';

const HomePage = () => {
  const { openLoginModal } = useLoginModal();
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasStores, setHasStores] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  const fetchStores = async () => {
    try {
      const data = await StoreServices.getStores();
      setStores(data);
      setHasStores(data.length > 0);
    } catch (error) {
      setError('Error al obtener las tiendas');
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchStores();
    }
  }, [isLoggedIn]);

  const handleComenzarClick = () => {
    if (!isLoggedIn) {
      openLoginModal('register');
    } else if (!hasStores) {
      setIsCreateStoreModalOpen(true);
    }
  };

  return (
    <div className="home-container">
      <Header />
      <div className="page-wrapper">
        <div className='home-pres'>
          <div className='hero-content'>
            <h1 className='hero-title'>
              <span className='gradient-text'>PRODEXPO</span>
              <span className='hero-subtitle'>Tu centro comercial virtual</span>
            </h1>
            <p className='hero-description'>Crea tu tienda online en minutos y llega a miles de clientes</p>
            <div className='hero-cta'>
              <button onClick={handleComenzarClick} className="primary-button">
                {!isLoggedIn ? 'Comienza Gratis' : !hasStores ? 'Crear Tienda' : 'Mi Tienda'}
              </button>
              <div className='hero-stats'>
                <div className='stat-item'>
                  <span className='stat-number'>1000+</span>
                  <span className='stat-label'>Tiendas activas</span>
                </div>
                <div className='stat-item'>
                  <span className='stat-number'>50k+</span>
                  <span className='stat-label'>Clientes felices</span>
                </div>
              </div>
            </div>
          </div>
          <div className='hero-image'>
            <img className='home-img' id='home-pres-img' src={shoppingw} alt='Experiencia de compra' />
          </div>
        </div>
        <div id='home-info-cards'>
          <div id='info-cards-text'>
            <h1 className='info-cards-title'>Desbloquea el potencial de tu negocio</h1>
            <p className='info-cards-subtitle'>Transforma tus ideas en una tienda online sobresaliente sin esfuerzo</p>
          </div>
          <div className='info-cards-container'>
            <div className='info-card'>
              <p className='info-card-title'>Diseños de alta conversion</p>
              <p className='info-card-description'>Comprobado y en constante investigacion para maximizar tus ventas.</p>
            </div>
            <div className='info-card'>
              <p className='info-card-title'>Interfaz amigable</p>
              <p className='info-card-description'>Queremos que el proceso sea simple y sin pasos innecesarios. Cualquier persona puede manejar nuestra app.</p>
            </div>
            <div className='info-card'>
              <p className='info-card-title'>Orientacion al cliente</p>
              <p className='info-card-description'>Siempre tiene la razon y nosotros lo sabemos ¡Un cliente satisfecho siempre vuelve!</p>
            </div>
            <div className='info-card'>
              <p className='info-card-title'>Sin necesidad de registro</p>
              <p className='info-card-description'>Tus clientes pueden ver y comprar productos sin necesidad de crear un perfil si no lo desea.</p>
            </div>
            <div className='info-card'>
              <p className='info-card-title'>Comisiones transparentes</p>
              <p className='info-card-description'>
                Solo 0,99% + IVA por venta* en nuestra plataforma. Sin costos ocultos, sin sorpresas adicionales.
              </p>
            </div>
          </div>
          <p className='info-card-description info-disclaimer'>*No incluye comisiones de Mercado Pago por procesamiento de pagos</p>
        </div>

        <div id='home-steps'>
          <div className='steps-text' id='info-cards-text'>
            <h1 className='info-cards-title' id='steps-title'>¿Cómo funciona?</h1>
            <p className='info-cards-subtitle' id='steps-subtitle'>Sigue estos sencillos pasos para abrir tu tienda online.</p>
            <img className='steps-img' id='steps-img' src={stepsImg} alt='cozy store'/>
          </div>
          <div className='info-cards-container' id='steps-container'>
            <div className='info-card' id='card-wrapper'>
              <p className='info-card-title'>Paso 1: Registrate</p>
              <p className='info-card-description'>Es totalmente gratuito y fácil. ¡Toma menos de un minuto!
                                                  <br/> Nada de procesos complicados ni tiempos de espera.
              </p>
            </div>
            <div className='info-card' id='card-wrapper'>
              <p className='info-card-title'>Paso 2: Personaliza</p>
              <p className='info-card-description'>Ingresa el nombre de tu tienda, logotipo y una dirección para acceder a ella.
                                                  <br/>esta sera la presentacion de tu propio local vitual.
              </p>
            </div>
            <div className='info-card' id='card-wrapper'>
              <p className='info-card-title'>Paso 3: Stockeate</p>
              <p className='info-card-description'>Agrega los productos o servicios que ofreces con sus imagenes,
                                                   descripciones, precio y stock. ¡Ya casi terminas! ¿sabes lo que sigue? 

              </p>
            </div>
            <div className='info-card' id='card-wrapper'>
              <p className='info-card-title'>Paso 4: ¡Vende!</p>
              <p className='info-card-description'>
                Copia el enlace o el codigo QR de tu tienda y ofrecelo a tus clientes. Con solo 0,99% + IVA de comisión por venta*, mantén más ganancias para tu negocio. Tus clientes podrán comprar de forma rápida y sencilla sin necesidad de registrarse.
                <br/>¡ya tienes tu propia tienda!
              </p>
            </div>
          </div>
        </div>
        <p className='info-card-description info-disclaimer'>*No incluye comisiones de Mercado Pago por procesamiento de pagos</p>
        <div className="cta-section">
          <h2 className="cta-title">¿Listo para empezar tu negocio online?</h2>
          <p className="cta-description">
            No esperes más para dar el siguiente paso. Únete a miles de emprendedores que ya están vendiendo en línea.
          </p>
          <button onClick={handleComenzarClick} className="cta-button">
            {!isLoggedIn ? 'Crear mi tienda ahora' : !hasStores ? 'Crear Tienda' : 'Mi Tienda'}
          </button>
        </div>
      </div>

      {isCreateStoreModalOpen && (
        <CreateStoreModal 
          onClose={() => setIsCreateStoreModalOpen(false)}
          onStoreCreated={() => {
            setIsCreateStoreModalOpen(false);
            window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default HomePage;
