import React, { useState, useRef, useCallback } from 'react';
import storeServices from '../../services/StoreServices';
import { useNavigate } from 'react-router-dom'; // Añadir esta importación
import SwipeableModal from '../GenericModal/SwipeableModal'; // Importa el componente SwipeableModal
import './CreateStoreModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const CreateStoreModal = ({ onClose, onStoreCreated }) => {
    const navigate = useNavigate(); // Añadir el hook useNavigate
    const [name, setName] = useState('');
    const [route, setRoute] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const [isRouteAvailable, setIsRouteAvailable] = useState(null);
    const [isChecking, setIsChecking] = useState(false);
    const routeCheckTimeoutRef = useRef(null);

    const handleLogoChange = useCallback((file) => {
        if (file) {
            setLogo(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setLogo(null);
            setLogoPreview(null);
        }
    }, []);

    const handleLogoClick = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleLogoChange(e.dataTransfer.files[0]);
        }
    }, [handleLogoChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const storeData = await storeServices.createStore(name, route, logo);
            console.log('Tienda creada:', storeData);
            onStoreCreated();
            navigate(`/${storeData.route}`);
            onClose();
        } catch (error) {
            console.error('Error al crear la tienda:', error);
        }
    };

    // Función para verificar la disponibilidad de la ruta
    const checkRouteAvailability = async (routeValue) => {
        if (!routeValue) {
            setIsRouteAvailable(null);
            return;
        }

        setIsChecking(true);
        try {
            const isAvailable = await storeServices.checkStoreRouteAvailability(routeValue);
            setIsRouteAvailable(isAvailable);
        } catch (error) {
            console.error('Error al verificar ruta:', error);
            setIsRouteAvailable(false);
        }
        setIsChecking(false);
    };

    // Manejador del cambio de ruta con debounce
    const handleRouteChange = (e) => {
        // Reemplazar cualquier caracter que no sea letra, número o guión
        const value = e.target.value
            .toLowerCase() // Convertir a minúsculas
            .replace(/[^a-z0-9-]/g, ''); // Solo permitir letras, números y guiones
        
        setRoute(value);

        // Limpiar el timeout anterior
        if (routeCheckTimeoutRef.current) {
            clearTimeout(routeCheckTimeoutRef.current);
        }

        // Establecer nuevo timeout para la verificación
        routeCheckTimeoutRef.current = setTimeout(() => {
            checkRouteAvailability(value);
        }, 500);
    };

    // Estilos dinámicos para el input de ruta
    const getRouteInputStyle = () => {
        if (isRouteAvailable === null) return {};
        return {
            borderColor: isRouteAvailable ? '#4CAF50' : '#f44336',
            borderWidth: '2px',
            borderStyle: 'solid'
        };
    };

    return (
        <SwipeableModal isOpen={true} onClose={onClose}>
            <div className="create-store-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Crear Tienda</h2>
                <div 
                    className={`image-upload-container ${isDragging ? 'drag-over' : ''}`}
                    onClick={handleLogoClick}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {logoPreview ? (
                        <img src={logoPreview} alt="Preview" className="uploaded-image-preview" />
                    ) : (
                        <div className="choose-file-button">
                            <span>Arrastra un logo o haz clic para seleccionar</span>
                        </div>
                    )}
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => handleLogoChange(e.target.files[0])}
                    style={{ display: 'none' }}
                    accept="image/*"
                />
                <form onSubmit={handleSubmit} className="store-creation-form">
                    <label>
                        <input
                            className='add-product-input'
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Nombre de tu tienda"
                            style={{ fontSize: '18px' }} // Ajusta el tamaño del texto
                            required
                        />
                    </label>
                    <div className="route-input-container" style={{ position: 'relative' }}>
                        <input
                            className='add-product-input'
                            type="text"
                            value={route}
                            onChange={handleRouteChange}
                            placeholder="URL única de tu tienda (ej: /
                            MiTienda)"
                            pattern="[a-z0-9-]*"
                            style={{ 
                                ...getRouteInputStyle(),
                                fontSize: '16px',
                                paddingRight: '30px'
                            }}
                            required
                        />
                        {isChecking && (
                            <FontAwesomeIcon 
                                icon={faSpinner} 
                                spin 
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '25%',
                                    transform: 'translateY(-50%)',
                                    color: '#666'
                                }}
                            />
                        )}
                    </div>
                    {isRouteAvailable === false && (
                        <span style={{ color: '#f44336', fontSize: '12px' }}>Esta URL ya está en uso</span>
                    )}
                    {isRouteAvailable === true && (
                        <span style={{ color: '#4CAF50', fontSize: '12px' }}>¡URL disponible!</span>
                    )}
                    <button type="submit" className="submit-button">Crear</button>
                </form>
            </div>
        </SwipeableModal>
    );
};

export default CreateStoreModal;
