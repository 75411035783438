import React, { useEffect, useState } from 'react';
import { initiateMPConnect } from '../../services/MercadoPagoService';
import './SetupMPModal.css';

const SetupMPModal = ({ storeRoute, onClose }) => {
  const [currentStep, setCurrentStep] = useState('connect'); // 'connect' o 'acreditation'
  const [acreditationType, setAcreditationType] = useState('immediate');
  const [scheduleDay, setScheduleDay] = useState(2); // Día por defecto para acreditación programada

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'mp_connected') {
        setCurrentStep('acreditation');
      } else if (event.data === 'mp_connection_failed') {
        console.error('Error al conectar con Mercado Pago');
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleSetupMP = async () => {
    try {
      const authUrl = await initiateMPConnect(storeRoute);
      window.open(authUrl, '_blank', 'width=800,height=600');
    } catch (error) {
      console.error('Error al iniciar configuración de MP:', error);
    }
  };

  const handleSaveAcreditationType = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/mercadopago/acreditation/${storeRoute}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          acreditationType,
          scheduleDay: acreditationType === 'scheduled' ? scheduleDay : 0
        }),
      });

      if (response.ok) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error al guardar tipo de acreditación:', error);
    }
  };

  const renderConnectStep = () => (
    <>
      <div className="setup-mp-header">
        <h2>Configura Mercado Pago</h2>
      </div>
      
      <div className="setup-mp-body">
        <p>Para comenzar a vender, necesitas vincular tu cuenta de Mercado Pago.</p>
        <p>Este paso es necesario para poder recibir pagos en tu tienda.</p>
        
        <div className="setup-mp-benefits">
          <h3>Beneficios:</h3>
          <ul>
            <li>Recibe pagos con tarjeta de crédito y débito</li>
            <li>Gestiona tus ventas desde la app de Mercado Pago</li>
            <li>Accede a reportes detallados de tus ventas</li>
          </ul>
        </div>
      </div>

      <div className="setup-mp-footer">
        <button 
          className="setup-mp-button"
          onClick={handleSetupMP}
        >
          Configurar Mercado Pago
        </button>
      </div>
    </>
  );

  const renderAcreditationStep = () => (
    <>
      <div className="setup-mp-header">
        <h2>Tipo de Acreditación*</h2>
      </div>
      
      <div className="setup-mp-body">
        <p>Selecciona cómo quieres recibir el dinero de tus ventas:</p>
        
        <div className="acreditation-options">
          <div className="acreditation-option">
            <input
              type="radio"
              id="immediate"
              name="acreditationType"
              value="immediate"
              checked={acreditationType === 'immediate'}
              onChange={(e) => setAcreditationType(e.target.value)}
            />
            <label htmlFor="immediate">
              <h3>Acreditación Inmediata</h3>
              <p>Recibe el dinero al instante con una comisión del 6.29% + IVA</p>
            </label>
          </div>

          <div className="acreditation-option">
            <input
              type="radio"
              id="scheduled"
              name="acreditationType"
              value="scheduled"
              checked={acreditationType === 'scheduled'}
              onChange={(e) => setAcreditationType(e.target.value)}
            />
            <label htmlFor="scheduled">
              <h3>Acreditación Programada</h3>
              <p>Recibe el dinero en días programados con una comisión entre el 1.49% y el 4.39%  + IVA</p>
            </label>
          </div>

          {acreditationType === 'scheduled' && (
            <div className="schedule-days">
              <label htmlFor="scheduleDay">Días para recibir el dinero:</label>
              <select
                id="scheduleDay"
                value={scheduleDay}
                onChange={(e) => setScheduleDay(Number(e.target.value))}
              >
                <option value="10">10 días (4.39%)</option>
                <option value="18">18 días (3.39%)</option>
                <option value="35">35 días (1.49%)</option>
              </select>
            </div>
          )}
        </div>
        <p>*Comisiones sujetas a cambios por Mercado Pago</p>
      </div>

      <div className="setup-mp-footer">
        <button 
          className="setup-mp-button"
          onClick={handleSaveAcreditationType}
        >
          Guardar Configuración
        </button>
      </div>
    </>
  );

  return (
    <div className="setup-mp-modal-backdrop" onClick={(e) => e.target.className === 'setup-mp-modal-backdrop' && onClose()}>
      <div className="setup-mp-modal-content">
        {currentStep === 'connect' ? renderConnectStep() : renderAcreditationStep()}
      </div>
    </div>
  );
};

export default SetupMPModal; 