import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from './CartContext';
import './CartModal.css';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const CartModal = ({ cartEntranceState, closeCart }) => {
  const { cart, removeFromCart, addToCart, updateCartItemQuantity, clearCart } = useContext(CartContext);
  const navigate = useNavigate();
  const { store } = useParams();
  const [storeID, setStoreID] = useState(null);

  useEffect(() => {
    const fetchStoreID = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/stores/${store}`);
        const data = await response.json();
        setStoreID(data._id);
      } catch (error) {
        console.error('Error fetching store:', error);
      }
    };

    if (store) {
      fetchStoreID();
    }
  }, [store]);

  const storeProducts = cart.filter(product => product.storeID === storeID);
  
  const total = storeProducts.reduce((acc, product) => acc + product.price * product.amount, 0).toFixed(2);
  
  let modalClass;
  if (cartEntranceState === "cartActionEntering") {
    modalClass = "crt-modal cartActionEntering";
  } else if (cartEntranceState === "cartActionExit") {
    modalClass = "crt-modal cartActionExit";
  } else {
    modalClass = "crt-modal";
  }

  const crearEfectoparticles = (e) => {
    const cantidadparticles = 20;
    for (let i = 0; i < cantidadparticles; i++) {
      let particle = document.createElement('div');
      particle.style.left = `${e.clientX}px`;
      particle.style.top = `${e.clientY}px`;
      particle.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
      document.body.appendChild(particle);
      particle.className = 'particle';

      const animation = particle.animate([
        { transform: 'scale(3)', opacity: 1 },
        { transform: `scale(0) translate(${Math.random() * 200 - 100}px, ${Math.random() * 200 - 100}px)`, opacity: 0 }
      ], {
        duration: Math.random() * 500 + 300,
        easing: 'ease-out',
        iterations: 1
      });

      animation.onfinish = () => particle.remove();
    }
  };

  const handlePurchase = (e) => {
    crearEfectoparticles(e);
    clearCart(storeID);
    navigate(`/${store}/checkout`);
  };

  return (
    <div className="crt-modal-backdrop" onClick={closeCart}>
      <button className='close-button-modal' id='close-cart-btn' onClick={closeCart}>X</button>
      <div className={modalClass} onClick={e => e.stopPropagation()}>
        <div className="crt-content">
          {storeProducts.map((product, index) => (
            <div key={index} className="crt-pdct">
              <div className="cart-pdct-section-1">
                <img src={product.imageUrls.thumbnail} alt={product.name} className="thumbnail-image" />
                <span className='crt-pdct-price'>${product.price}</span>
              </div>
              <div className='crt-pdct-section-2'>
                <span className='crt-pdct-name'>{product.name}</span>
              <div className='amount-handler'>
                <div className='amount-handler-wrapper'>
                  <button className='remove-cart-item-btn' onClick={() => removeFromCart(product._id)}>
                    <FontAwesomeIcon icon={faMinus} style={{color: "#ffffff"}} />
                  </button>
                  <input
                    type="number"
                    className='crt-pdct-amount'
                    value={product.amount}
                    onChange={(e) => {
                      const newAmount = parseInt(e.target.value, 10);
                      if (!isNaN(newAmount) && newAmount >= 0 && newAmount <= product.stock) {
                        updateCartItemQuantity(product._id, newAmount);
                      }
                    }}
                    min="0"
                    max={product.stock}
                  />
                  <button 
                    className='add-cart-item-btn' 
                    onClick={() => addToCart(product)} 
                    disabled={product.amount >= product.stock}
                  >
                    <FontAwesomeIcon icon={faPlus} style={{color: "#ffffff"}} />
                  </button>
                </div>
                <span className='crt-pdct-total'>${(product.amount * product.price).toFixed(2)}</span>
              </div>
              </div>
            </div>
          ))}
        </div>
        <div className='crt-total-section'>
          <div className='delivery-warning'>Atención: el costo de envio no esta incluido en el precio</div>
          <div className="cart-total">
            <span>Total: ${total}</span>
          </div>
          <div className="cart-actions">
            <button 
              className='purchase-btn' 
              onClick={handlePurchase}
              disabled={storeProducts.length === 0}
            >
              Comprar
            </button>
            <button className='clear-cart-btn' onClick={clearCart}>
              <FontAwesomeIcon icon={faTrashCan} style={{color: "#ffffff"}} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartModal;
