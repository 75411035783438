import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./SwipeableModal.css";

const SwipeableModal = ({ isOpen, onClose, children }) => {
  const contentRef = useRef(null);
  const touchStartY = useRef(0);
  const [canDrag, setCanDrag] = useState(true);
  const closeThreshold = 140; // Distancia mínima para cerrar (se puede ajustar según necesidades)

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
    if (contentRef.current) {
      setCanDrag(contentRef.current.scrollTop <= 0);
    }
  };

  const handleTouchMove = (e) => {
    if (!canDrag) return;

    const touchY = e.touches[0].clientY;
    const deltaY = touchY - touchStartY.current;

    if (Math.abs(deltaY) > closeThreshold * 2) {
      onClose();
      e.preventDefault();
    }
  };

  const handleDragEnd = (event, info) => {
    const { y: offsetY } = info.offset;

    if (Math.abs(offsetY) > closeThreshold * 2) {
      onClose();
    }
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <div
          className="swipeable-modal-backdrop"
          onClick={onClose}
        >
          <motion.div
            className="swipeable-modal-content"
            onClick={(e) => e.stopPropagation()}
            ref={contentRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onDragEnd={handleDragEnd}
            drag="y"
            dragConstraints={{ top: 0, bottom: 0 }}
            dragElastic={0.2}
            initial={{ y: "20%" }}
            animate={{ y: 0 }}
            exit={{ y: "150%" }}
          >
            <div className="drag-handle" />
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SwipeableModal;