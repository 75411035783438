import { jwtDecode } from 'jwt-decode';
import { openLoginModal } from './UserService';
const API_URL = process.env.REACT_APP_API_URL;

// Función de login que guarda el access token en localStorage
const login = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/users/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
      credentials: 'include',
    });

    if (!response.ok) throw new Error('Error en la solicitud de login');

    const data = await response.json();
    if (data.accessToken) {
      // Guardar el access token en localStorage
      localStorage.setItem('accessToken', data.accessToken);
    }
    return data;
  } catch (error) {
    console.error('Error en login:', error);
    throw error;
  }
};

// Función para renovar el access token usando el refresh token
const renewAccessToken = async () => {
  try {
    const response = await fetch(`${API_URL}/users/refresh-token`, {
      method: 'POST',
      credentials: 'include', // Enviar cookies con el refresh token
    });

    if (!response.ok) throw new Error('Error al renovar el access token');

    const data = await response.json();
    if (data.accessToken) {
      // Guardar el nuevo access token
      localStorage.setItem('accessToken', data.accessToken);
      return data.accessToken;
    }
    return null;
  } catch (error) {
    console.error('Error al renovar el access token:', error);
    return null;
  }
};


const handle401Error = async (originalRequestCallback) => {
  try {
    const newAccessToken = await renewAccessToken();
    if (newAccessToken) {
      return originalRequestCallback();
    }
    throw new Error('Error al renovar el access token');
  } catch (error) {
    localStorage.removeItem('accessToken');
    openLoginModal();
    throw error;
  }
};

// Función para obtener datos del usuario
const getUserData = async () => {
  const token = localStorage.getItem('accessToken'); // Obtener el token del localStorage
  try {
    const response = await fetch(`${API_URL}/users/me`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Error al obtener los datos del usuario');
    const userData = await response.json();
    return userData;
  } catch (error) {
    console.error('Error al obtener los datos del usuario:', error);
    throw error;
  }
};

// Función para registrar un nuevo usuario
const register = async (userData) => {
  try {
    const response = await fetch(`${API_URL}/users/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
      credentials: 'include',
    });

    if (!response.ok) throw new Error('Error en la solicitud de registro');

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

 const logout = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/logout`, {
      method: 'POST',
      credentials: 'include',
    });
    
    if (!response.ok) {
      throw new Error('Error en el servidor al cerrar sesión');
    }
  } finally {
    localStorage.removeItem('accessToken');
  }
};


const socialLogin = async (firebaseToken) => {
  try {
    const response = await fetch(`${API_URL}/users/social-login`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${firebaseToken}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Error en el login social');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error en socialLogin:', error);
    throw error;
  }
};

const completeSocialRegistration = async (userData) => {
    try {
        const response = await fetch(`${API_URL}/users/complete-social-registration`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userData.firebaseToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstName: userData.firstName,
                lastName: userData.lastName,
                dni: userData.dni,
                areaCode: userData.areaCode,
                phoneNumber: userData.phoneNumber
            }),
            credentials: 'include'
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Error al completar el registro social');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error en completeSocialRegistration:', error);
        throw error;
    }
};

// Agregar nueva función para renovación automática
const setupTokenRenewal = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return;

  try {
    const decoded = jwtDecode(token);
    if (!decoded.exp) {
      console.error('Token no contiene información de expiración');
      return;
    }

    const expirationTime = decoded.exp * 1000 - 60000; // 1 minuto antes
    const timeUntilExpiration = expirationTime - Date.now();
    
    if (timeUntilExpiration > 0) {
      setTimeout(async () => {
        try {
          await renewAccessToken();
          setupTokenRenewal(); // Configurar la siguiente renovación
        } catch (error) {
          console.error('Error en renovación automática:', error);
          openLoginModal();
        }
      }, timeUntilExpiration);
    } else {
      // Si el token ya está expirado o está por expirar
      renewAccessToken().catch(error => {
        console.error('Error en renovación inmediata:', error);
        openLoginModal();
      });
    }
  } catch (error) {
    console.error('Error al decodificar token:', error);
    // Intentar renovar el token si hay error al decodificarlo
    renewAccessToken().catch(() => openLoginModal());
  }
};

// Agregar al objeto exportado
const authService = {
    login,
    renewAccessToken,
    getUserData,
    register,
    handle401Error,
    logout,
    socialLogin,
    completeSocialRegistration,
    setupTokenRenewal
};

export default authService;
