import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import PaymentFailure from '../components/Payment/PaymentFailure';
import { getOrderDetails } from '../services/OrderService';
import { checkPaymentStatus } from '../services/PaymentService';
import './ThankYouPage.css';

const ThankYouPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const storeRoute = location.pathname.split('/')[1];
  const MAX_ATTEMPTS = 20; // Máximo número de intentos (1 minuto con intervalo de 3 segundos)
  const [attempts, setAttempts] = useState(0);

  const fetchOrderDetails = async () => {
    try {
      // Si ya alcanzamos el máximo de intentos o el pago está aprobado, detener
      if (attempts >= MAX_ATTEMPTS || paymentStatus === 'approved') {
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/order-status/${orderId}/${storeRoute}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Error al obtener el estado del pago');
      }

      const data = await response.json();
      setPaymentStatus(data.status);
      setOrderData(data.order);
      setLoading(false);
      setAttempts(prev => prev + 1);

      // Si el pago está aprobado, detener las consultas
      if (data.status === 'approved') {
        return true; // Indica que debemos detener el intervalo
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      setLoading(false);
      return true; // También detenemos en caso de error
    }
  };

  useEffect(() => {
    fetchOrderDetails();
    
    const intervalId = setInterval(async () => {
      const shouldStop = await fetchOrderDetails();
      if (shouldStop || attempts >= MAX_ATTEMPTS) {
        clearInterval(intervalId);
      }
    }, 3000);

    // Limpiar el intervalo después de 1 minuto o si el componente se desmonta
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 60000); // 1 minuto

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [orderId, storeRoute]);

  // Mostrar mensaje cuando se alcanza el límite de intentos
  if (attempts >= MAX_ATTEMPTS && paymentStatus !== 'approved') {
    return (
      <div className="thankyou-page">
        <Header />
        <div className="thankyou-container">
          <div className="warning">
            <h2>Estado del pago pendiente</h2>
            <p>El estado de tu pago aún está pendiente. Puedes verificar el estado más tarde en tu historial de órdenes.</p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="thankyou-page">
        <Header />
        <div className="thankyou-container">
          <div className="loading">Verificando estado del pago...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="thankyou-page">
        <Header />
        <div className="thankyou-container">
          <div className="error">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="thankyou-page">
      <Header />
      <div className="thankyou-container">
        <div className="success-header">
          <h1>¡Gracias por tu compra!</h1>
          
          {orderData && (
            <div className="order-details">
              <div className="order-header">
                <h2>Orden #{orderData.orderNumber}</h2>
                <p className="order-date">
                  Fecha: {new Date(orderData.createdAt).toLocaleDateString()}
                </p>
                <p className={`order-status ${paymentStatus}`}>
                  Estado del pago: {paymentStatus === 'approved' ? 'Aprobado' : 'Pendiente'}
                </p>
              </div>

              <div className="products-section">
                <h3>Productos</h3>
                <div className="products-list">
                  {orderData.products.map((item, index) => (
                    <div key={index} className="product-item">
                        <div className="product-thumbnail">
                            {item.productId?.imageUrls?.thumbnail && (
                                <img
                                    src={item.productId.imageUrls.thumbnail}
                                    alt={item.productId.name}
                                />
                            )}
                        </div>
                        <div className="product-info">
                            <h4>{item.productId.name}</h4>
                            <p className="product-price">
                                ${item.price} x {item.quantity} unidades
                            </p>
                        </div>
                        <div className="product-total">
                            ${(item.price * item.quantity).toFixed(2)}
                        </div>
                    </div>
                  ))}
                </div>
                
                <div className="order-total">
                  <h3>Total: ${orderData.total}</h3>
                </div>
              </div>

              {orderData.user && (
                <div className="shipping-info">
                  <h3>Información de envío</h3>
                  <div className="shipping-details">
                    <p><strong>Nombre:</strong> {orderData.user.firstName} {orderData.user.lastName}</p>
                    <p><strong>Email:</strong> {orderData.user.email}</p>
                    <p><strong>Teléfono:</strong> {orderData.user.phoneNumber}</p>
                    <p><strong>Dirección:</strong> {orderData.user.address}</p>
                    <p><strong>Ciudad:</strong> {orderData.user.city}</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {paymentStatus === 'approved' ? (
            <div className="success-message">
              <p>Tu pago ha sido procesado exitosamente</p>
              <p>Recibirás un email con los detalles de tu compra</p>
            </div>
          ) : (
            <div className="pending-message">
              <p>Estamos procesando tu pago...</p>
              <p>Este proceso puede tomar unos minutos</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
