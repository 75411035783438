// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import Header from './components/Header/Header';
import LoginModal from './components/Login/LoginModal';
import ProductGallery from './pages/ProductGallery';
import CheckoutPage from './pages/CheckoutPage'; 
import ThankYouPage from './pages/ThankYouPage'; 
import MyOrdersPage from './pages/MyOrdersPage';
import { CartProvider } from './components/Cart/CartContext';
import { Routes, Route, useParams, Navigate, useNavigate } from 'react-router-dom';
import { LoginModalProvider } from './components/Login/LoginModalContext';
import PaymentRedirectPage from './pages/PaymentRedirectPage';
import authService from './services/AuthServices';

function UserBasedContent() {
  const { store } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [storeLogo, setStoreLogo] = useState(null);
  const [storeRoute, setStoreRoute] = useState(null);

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  useEffect(() => {
    const verificarTienda = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/stores/${store}`);
        const data = await response.json();
        console.log(data);
        setLoading(false);
        if (!response.ok) {
          navigate('/NotFoundPage');
        } else {
          setStoreLogo(data.logoUrl);
          setStoreRoute(`/${data.route}`);
        }
      } catch (error) {
        console.error('Error al verificar la tienda:', error);
        setLoading(false);
        navigate('/NotFoundPage');
      }
    };

    verificarTienda();
  }, [store, navigate]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <Header 
        toggleLoginModal={toggleLoginModal} 
        storeLogo={storeLogo} 
        storeRoute={storeRoute} 
      />
      {isLoginModalOpen &&
        <div className='login-backdrop' onClick={() => setIsLoginModalOpen(false)}>
          <LoginModal onClose={toggleLoginModal} />
        </div>
      }
      <ProductGallery store={store} />
    </>
  );
}

function OrderBasedContent() {
  const { store } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verificarTienda = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/stores/${store}`);
        setLoading(false);
        if (!response.ok) {
          navigate('/NotFoundPage');
        }
      } catch (error) {
        console.error('Error al verificar la tienda:', error);
        setLoading(false);
        navigate('/NotFoundPage');
      }
    };

    verificarTienda();
  }, [store, navigate]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <Header />
      <MyOrdersPage key={store} store={store} />
    </>
  );
}

function App() {
  useEffect(() => {
    // Configurar la renovación automática de token al iniciar la app
    authService.setupTokenRenewal();
  }, []);

  console.log("App component mounted");
  return (
    <LoginModalProvider>
      <CartProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/NotFoundPage" element={<NotFoundPage />} />
            <Route path="/:store" element={<UserBasedContent />} />
            <Route path="/:store/checkout" element={<CheckoutPage />} />
            <Route path="/:store/orders" element={<OrderBasedContent />} />
            <Route path="/payment/redirect/:status" element={<PaymentRedirectPage />} />
            <Route path="/payment/redirect" element={<PaymentRedirectPage />} />
            <Route path="/:store/thankyou/:orderId" element={<ThankYouPage />} />
            <Route path="*" element={<Navigate replace to="/NotFoundPage" />} />
          </Routes>
        </div>
      </CartProvider>
    </LoginModalProvider>
  );
}

export default App;
