import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Initialize cart from localStorage if available
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product, storeId) => {
    const productExists = cart.find((item) => item._id === product._id);
    if (productExists) {
      setCart(
        cart.map((item) =>
          item._id === product._id ? { ...item, amount: item.amount + 1 } : item
        )
      );
    } else {
      setCart([...cart, { ...product, amount: 1, storeID: product.storeID }]);
    }
  };

  const removeFromCart = (productId) => {
    console.log('Removing product with ID:', productId);
    console.log('Current cart before removal:', cart);
    
    setCart((currentCart) => {
      const itemIndex = currentCart.findIndex((item) => item._id === productId);
      if (itemIndex === -1) {
        console.error('Product not found in cart. Product ID:', productId);
        console.log('Cart items:', currentCart.map(item => ({ id: item._id, name: item.name })));
        return currentCart;
      }
      
      const updatedCart = [...currentCart];
      if (updatedCart[itemIndex].amount > 1) {
        updatedCart[itemIndex] = {
          ...updatedCart[itemIndex],
          amount: updatedCart[itemIndex].amount - 1
        };
      } else {
        updatedCart.splice(itemIndex, 1);
      }
      console.log('Updated cart after removal:', updatedCart);
      return updatedCart;
    });
  };

  const clearCart = (storeID = null) => {
    if (storeID) {
      // Si se proporciona un storeID, mantener solo los productos que NO son de esa tienda
      setCart(currentCart => currentCart.filter(item => item.storeID === storeID));
    } else {
      // Si no se proporciona storeID, limpiar todo el carrito
      setCart([]);
    }
  };

  const createOrder = async (actualRoute, userData = null) => {
    const token = localStorage.getItem('token');
    
    // Filtramos los productos por tienda
    const storeProducts = cart.filter(product => product.storeID === actualRoute);

    const subTotal = storeProducts.reduce((acc, product) => acc + product.amount * product.price, 0);

    // Estructura básica de la orden
    // Mapeamos los productos del carrito con uuid
    const orderProducts = cart.map(product => ({
        uuid: product.uuid,
        quantity: product.amount,
        price: product.price
    }));

    // Estructura de la orden actualizada
    const orderData = {
        products: orderProducts,
        subTotal: cart.reduce((acc, product) => acc + product.amount * product.price, 0),
        route: actualRoute,
        user: userData
    };
    
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(token && { 'Authorization': `Bearer ${token}` }),
            },
            body: JSON.stringify(orderData),
        });

        if (!response.ok) {
            throw new Error('Error al crear la orden');
        }

        const result = await response.json();
        clearCart(actualRoute);
        return result.data; // Devuelve la orden con su ID
    } catch (error) {
        console.error('Error en la solicitud de creación de orden:', error);
        throw error;
    }
  };

  const updateCartItemQuantity = (productId, newQuantity) => {
    setCart(prevCart => 
      prevCart.map(item => 
        item._id === productId 
          ? { ...item, amount: newQuantity }
          : item
      )
    );
  };

  return (
    <CartContext.Provider value={{ 
      cart, 
      addToCart, 
      removeFromCart, 
      clearCart, 
      createOrder, 
      updateCartItemQuantity 
    }}>
      {children}
    </CartContext.Provider>
  );
};
