import React, { useState, useRef, useCallback } from 'react';
import './AddProductModal.css';
import { createProduct } from '../../services/ProductServices';
import SwipeableModal from '../GenericModal/SwipeableModal';

const AddProductModal = ({ store, onClose, onProductAdded }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState('');
    const [image, setImage] = useState(null);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const fileInputRef = useRef(null);

    const handleImageChange = useCallback((file) => {
        if (file) {
            setImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImage(null);
            setImagePreview(null);
        }
    }, []);

    const handleImageClick = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleImageChange(e.dataTransfer.files[0]);
        }
    }, [handleImageChange]);

    const handleClose = () => {
        setIsModalOpen(false);
        setTimeout(onClose, 300);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const productData = {
            name,
            description,
            price,
            stock,
            isFeatured,
            isHidden,
        };
        
        try {
            await createProduct(store, productData, image);
            onProductAdded();
            handleClose();
        } catch (error) {
            console.error('Error al agregar el producto:', error);
        }
    };
    
    return (
        <SwipeableModal isOpen={isModalOpen} onClose={handleClose}>
            <div className="add-product-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Agregar Producto</h2>
                <div 
                    className={`image-upload-container ${isDragging ? 'drag-over' : ''}`}
                    onClick={handleImageClick}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {imagePreview ? (
                        <img src={imagePreview} alt="Preview" className="uploaded-image-preview" />
                    ) : (
                        <div className="choose-file-button">
                            <span>Arrastra una imagen o haz clic para seleccionar</span>
                        </div>
                    )}
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => handleImageChange(e.target.files[0])}
                    style={{ display: 'none' }}
                    accept="image/*"
                />
                <form onSubmit={handleSubmit} className="add-product-form">
                    <label>
                        <input className='add-product-input' 
                        id='add-product-name'
                        type="text" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        placeholder="Nombre del producto..." 
                        required />
                    </label>
                    <label>
                        <textarea className='add-product-input' 
                        id='add-product-description'
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                        placeholder="descripcion del producto..." 
                        required />
                    </label>
                    <div className='add-product-price-stock-container'>
                        <div className='input-group'>
                            <label>Precio</label>
                            <input className='add-product-input'
                                type="number" 
                                value={price} 
                                onChange={(e) => setPrice(e.target.value)} 
                                placeholder="- - -" 
                                inputmode="numeric"
                                required />
                        </div>
                        <div className='input-group'>
                            <label>Cantidad</label>
                            <input className='add-product-input' 
                                type="number" 
                                value={stock} 
                                onChange={(e) => setStock(e.target.value)} 
                                placeholder="- - -" 
                                inputmode="numeric"
                                required />
                        </div>
                    </div>
                    <div className="checkbox-container">
                        <div className="checkbox-wrapper">
                            <input
                                type="checkbox"
                                checked={isFeatured}
                                onChange={(e) => setIsFeatured(e.target.checked)}
                                className="checkbox-input"
                            />
                            <span>Producto destacado</span>
                        </div>
                        <div className="checkbox-wrapper">
                            <input
                                type="checkbox"
                                checked={isHidden}
                                onChange={(e) => setIsHidden(e.target.checked)}
                                className="checkbox-input"
                            />
                            <span>Ocultar producto</span>
                        </div>
                    </div>
                    <div className="file-input-container">
                    </div>
                    <button type="submit" className="submit-button">Agregar</button>
                </form>
            </div>
        </SwipeableModal>
    );
};

export default AddProductModal;
