import React, { useState, useEffect, useCallback } from 'react';
import DraggableProduct from '../components/Product/DraggableProduct';
import ProductModal from '../components/Product/ProductModal';
import AddProductModal from '../components/Product/AddProductModal';
import EditProductModal from '../components/Product/EditProductModal';
import SetupMPModal from '../components/MercadoPago/SetupMPModal';
import './ProductGallery.css';
import { fetchProducts, updateProductPositionOnServer, handleDeleteProduct } from '../services/ProductServices';  // Importamos las funciones
import { useParams } from 'react-router-dom';
import Header from '../components/Header/Header';
import storeServices from '../services/StoreServices';


const ProductGallery = () => {
  const [products, setProducts] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [hasMercadoPago, setHasMercadoPago] = useState(true);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMPModal, setShowMPModal] = useState(true);
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState(null);
  const { store } = useParams();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(true);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/stores/${store}`);
        const data = await response.json();
        setCurrentStore(data);
      } catch (error) {
        console.error('Error al obtener datos de la tienda:', error);
      }
    };

    const fetchUserStores = async () => {
      try {
        const stores = await storeServices.getStores();
        setStores(stores);
      } catch (error) {
        console.error('Error al obtener las tiendas:', error);
      }
    };

    fetchStoreData();
    fetchUserStores();
  }, [store]);

  useEffect(() => {
    const checkStoreOwnership = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/stores/${store}/isOwner`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
        const data = await response.json();
        setIsOwner(data.isOwner);
        setHasMercadoPago(data.hasMercadoPago);
      } catch (error) {
        console.error('Error verificando propiedad de la tienda:', error);
      }
    };

    checkStoreOwnership();
  }, [store]);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (currentStore?._id) {
        try {
          const { isFavorite } = await storeServices.checkFavoriteStatus(currentStore._id);
          setIsFavorite(isFavorite);
        } catch (error) {
          console.error('Error al verificar favorito:', error);
        } finally {
          setIsLoadingFavorite(false);
        }
      }
    };

    checkFavoriteStatus();
  }, [currentStore]);

  const fetchAndSetProducts = useCallback(async () => {
    try {
      const data = await fetchProducts(store);
      const visibleProducts = data.products.filter(producto => (!producto.isHidden)  || data.isOwner);
      setProducts(visibleProducts);
      setIsOwner(data.isOwner);
    } catch (error) {
      console.error('Error al cargar productos:', error);
    }
  }, [store]);

  useEffect(() => {
    fetchAndSetProducts();
  }, [store, fetchAndSetProducts]);

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const openAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  const closeAddProductModal = () => {
    setIsAddProductModalOpen(false);
  };

  const openEditProductModal = (product) => {
    setProductToEdit(product);
    setIsEditProductModalOpen(true);
  };

  const closeEditProductModal = () => {
    setProductToEdit(null);
    setIsEditProductModalOpen(false);
  };

  const handleDelete = async (uuid) => {
    try {
      await handleDeleteProduct(store, uuid);
      fetchAndSetProducts();
      closeModal();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  const updatePosition = async (updatedProducts) => {
    try {
      await updateProductPositionOnServer(store, updatedProducts);
    } catch (error) {
      console.error('Error al actualizar las posiciones:', error);
    }
  };

  const handleSetupMP = () => {
    // Aquí irá la lógica para iniciar la configuración de MP
    console.log('Iniciando configuración de Mercado Pago');
  };

  const handleFavoriteToggle = async () => {
    if (!currentStore?._id) return;
    
    try {
      const { isFavorite: newStatus } = await storeServices.toggleFavoriteStore(currentStore._id);
      setIsFavorite(newStatus);
    } catch (error) {
      console.error('Error al actualizar favorito:', error);
    }
  };

  return (
    <div className="gallery-background">
      <Header 
        storeLogo={currentStore?.logoUrl}
        storeRoute={store}
        stores={stores}
        storeData={currentStore}
        isFavorite={isFavorite}
        isLoadingFavorite={isLoadingFavorite}
        onFavoriteToggle={handleFavoriteToggle}
      />
      {isOwner && (
        <div className="button-container">
          <button onClick={openAddProductModal} className="add-product-button">
            Agregar Producto
          </button>
        </div>
      )}
      <DraggableProduct
        products={products}
        setProducts={setProducts}
        isOwner={isOwner}
        openEditProductModal={openEditProductModal}
        openModal={openModal}
        updateOrder={updatePosition}
      />

      {/* Modal de configuración de MP */}
      {isOwner && !hasMercadoPago && showMPModal && (
        <SetupMPModal 
          storeRoute={store} 
          onClose={() => setShowMPModal(false)}
        />
      )}

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={closeModal}
          onEdit={openEditProductModal}
          onDelete={handleDelete}
          esDueno={isOwner}
        />
      )}
      {isAddProductModalOpen && (
        <AddProductModal
          store={store}
          onClose={closeAddProductModal}
          onProductAdded={() => fetchAndSetProducts()}
        />
      )}
      {isEditProductModalOpen && (
        <EditProductModal
          store={store}
          producto={productToEdit}
          onClose={closeEditProductModal}
          onProductEdited={() => fetchAndSetProducts()}
        />
      )}
    </div>
  );
};

export default ProductGallery;
